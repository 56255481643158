import React, {Component} from "react";
import {Stepper, Step, StepLabel} from "@material-ui/core";

class ServiceNavigation extends Component {
	render() {
		const {values} = this.props;

		return (
			<Stepper activeStep={values.step - 1} alternativeLabel>
				{values.tabs.map(tab => {
					return (
						<Step key={tab.tabNumber}>
							<StepLabel>{tab.tabText}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		);
	}
}

export default ServiceNavigation;