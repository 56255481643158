import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import red from '@material-ui/core/colors/red';
import {KeyboardArrowLeft} from "@material-ui/icons";

const styles = theme => ({
/*	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},*/
});

const theme = createMuiTheme({
	palette: {
		primary: red,
	},
	typography: {
		useNextVariants: true,
		fontFamily: [
			'Arial',
			'"Helvetica Neue"',
			'Helvetica',
			'sans-serif'
		].join(','),
		fontSize: 12
	},
});

function BackButton(props) {
	const { classes, onClick } = props;

	return (
		<MuiThemeProvider theme={theme}>
			<Button
				variant="contained"
				color="primary"
				disableRipple
				className={classNames(classes.margin)}
				onClick={onClick}
			>
				<KeyboardArrowLeft style={{marginRight: 5}} />
				Natrag
			</Button>
		</MuiThemeProvider>
	);
}

BackButton.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BackButton);