import React, {Component} from "react";
import { Grid, Paper, FormControl, InputLabel, Select, Input, MenuItem, Button, TextField, Typography,
	FormHelperText, Hidden } from "@material-ui/core";
import SelectionOverview from "./bits/SelectionOverview";
import ReactLoading from "react-loading";
import {Consumer} from "../context";
import BackButton from "./bits/BackButton";
import {KeyboardArrowRight} from "@material-ui/icons";

export default class FormClient extends Component {
	componentDidMount() {
		// Pull dispatch from context
		const {dispatch} = this.props.context;
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_APP_BAR_VISIBILITY', payload: true});
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_SERVICE_NAVIGATION_VISIBILITY', payload: true});

		// Don't load data again if form has been loaded previously
		if(!this.props.context.formClientMounted) {
			// Load service operations data
			this.props.context.loadLanguages();
		}

		// Set form as mounted
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'formClientMounted', 'value': true}});
	}

	// Handles on continue button click
	onContinueButtonClick = (dispatch) => {
		dispatch({type: 'NEXT_STEP'})
	};

	// Handles on back button click
	onBackButtonClick = (dispatch) => {
		dispatch({type: 'PREVIOUS_STEP'})
	};

	// Handles on change event
	onChangeEvent = (dispatch, e) =>  {
		// Set target name and values
		const { target: { value, name } } = e;
		// Dispatch daa
		dispatch({type: 'HANDLE_SIMPLE_FORM_DATA_CHANGE', payload: {'fieldName': name, 'value': value}});
	};

	render() {
		return (
			<Consumer>
				{value => {
					// Extract form data
					const {formData} = value;
					// Check if button should be enabled
					const isButtonEnabled = value.formValidation.clientEmailValid && formData.clientEmail.length
						&& formData.clientLastName && formData.clientStreetAndNumber.length && formData.clientMobile;

					// Set loading element
					let divClientLanguages = <ReactLoading type={"spokes"} color={"#1c69d4"}/>;
					// Set element only after data is loaded
					if (!value.languagesLoading) {
						divClientLanguages =
							<FormControl fullWidth={false}>
								<InputLabel>Jezik</InputLabel>
								<Select
									value={formData.clientLanguageId}
									input={<Input id="languageId"/>}
									name={'clientLanguageId'}
									onChange={this.onChangeEvent.bind(this, value.dispatch)}
								>
									{value.languages.map((lang) => {
											return <MenuItem key={lang.id} value={lang.id}>{lang.name}</MenuItem>
										}
									)}
								</Select>
							</FormControl>
					}

					return (
						<Grid container>
							<Grid item xs={12} md={7}>
								<Paper className={"paper"} elevation={2}>
									<Typography variant="h5" style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
										Kontaktni podaci
									</Typography>
									<div style={divFormStyle}>
										<form autoComplete="off" style={{marginBottom: 20, fontSize: 15}}>
											<FormControl fullWidth={true} margin={"normal"} error={!value.formValidation.clientEmailValid}>
												<InputLabel htmlFor="txtClientEmail">E-pošta *</InputLabel>
												<Input
													id="txtClientEmail"
													name={"clientEmail"}
													onChange={this.onChangeEvent.bind(this, value.dispatch)}
													inputProps={{maxLength: 300}}
													value={value.formData.clientEmail}
													required/>
												<FormHelperText
													style={{color: 'red'}}>{value.formValidation.clientEmailValid ? "Obvezno polje" : "Neispravan email"}</FormHelperText>
											</FormControl>
											<Grid container spacing={8}>
												<Grid item sm={6}>
													<TextField
														label="Ime"
														margin="normal"
														fullWidth={true}
														name={"clientFirstName"}
														value={value.formData.clientFirstName}
														onChange={this.onChangeEvent.bind(this, value.dispatch)}
														inputProps={{maxLength: 300}}
													/>
												</Grid>
												<Grid item sm={6}>
													<FormControl fullWidth={true} margin={"normal"}>
														<InputLabel htmlFor="txtClientLastName">Prezime *</InputLabel>
														<Input
															id="txtClientLastName"
															name={"clientLastName"}
															value={value.formData.clientLastName}
															onChange={this.onChangeEvent.bind(this, value.dispatch)}
															inputProps={{
																maxLength: 300
															}}
															required/>
														<FormHelperText style={{color: 'red'}}>Obvezno polje</FormHelperText>
													</FormControl>
												</Grid>
											</Grid>
											<FormControl fullWidth={true} margin={"normal"}>
												<InputLabel htmlFor="txtClientLastName">Ulica i broj *</InputLabel>
												<Input
													id="txtClientStreetAndNumber"
													name={"clientStreetAndNumber"}
													value={value.formData.clientStreetAndNumber}
													onChange={this.onChangeEvent.bind(this, value.dispatch)}
													inputProps={{maxLength: 300}}
													required/>
												<FormHelperText style={{color: 'red'}}>Obvezno polje</FormHelperText>
											</FormControl>
											<Grid container spacing={8}>
												<Grid item sm={4}>
													<TextField
														label="Poštanski broj"
														margin="normal"
														fullWidth={true}
														name={"clientPostalCode"}
														value={value.formData.clientPostalCode}
														onChange={this.onChangeEvent.bind(this, value.dispatch)}
													/>
												</Grid>
												<Grid item sm={4}>
													<TextField
														label="Mjesto"
														margin="normal"
														fullWidth={true}
														name={"clientCity"}
														value={value.formData.clientCity}
														onChange={this.onChangeEvent.bind(this, value.dispatch)}
														inputProps={{maxLength: 300}}
													/>
												</Grid>
												<Grid item sm={4}>
													<TextField
														label="Država"
														margin="normal"
														fullWidth={true}
														name={"clientCountry"}
														value={value.formData.clientCountry}
														onChange={this.onChangeEvent.bind(this, value.dispatch)}
														inputProps={{maxLength: 300}}
													/>
												</Grid>
											</Grid>
											<Grid container spacing={8}>
												<Grid item sm={6}>
													<FormControl fullWidth={true} margin={"normal"}>
														<InputLabel htmlFor="txtClientMobile">Broj mobitela *</InputLabel>
														<Input
															id="txtClientMobile"
															name={"clientMobile"}
															value={value.formData.clientMobile}
															onChange={this.onChangeEvent.bind(this, value.dispatch)}
															inputProps={{maxLength: 300}}
															required/>
														<FormHelperText style={{color: 'red'}}>Obvezno polje</FormHelperText>
													</FormControl>
												</Grid>
												<Grid item sm={6}>
													<TextField
														label="Telefon"
														margin="normal"
														fullWidth={true}
														name={"clientTelephone"}
														value={value.formData.clientTelephone}
														onChange={this.onChangeEvent.bind(this, value.dispatch)}
														inputProps={{maxLength: 300}}
													/>
												</Grid>
											</Grid>
											<Grid container spacing={8} style={{marginBottom: 20}}>
												<Grid item sm={3}>
													{divClientLanguages}
												</Grid>
											</Grid>
										</form>
									</div>
								</Paper>
								<Grid item xs={12} style={{paddingRight: 10}}>
									<Button style={{float: 'right'}}
													variant="contained"
													color="primary"
													disabled={!isButtonEnabled}
													onClick={this.onContinueButtonClick.bind(this, value.dispatch)}
									>
										Dalje do vozila
										<KeyboardArrowRight style={{marginLeft: 5}} />
									</Button>
								</Grid>
								<Grid item xs={12}>
									<div style={{marginLeft: 10}}>
										<BackButton onClick={this.onBackButtonClick.bind(this, value.dispatch)} />
									</div>
								</Grid>
							</Grid>
							<Hidden smDown>
								<Grid item md={5}>
									<SelectionOverview
										values={value}
									/>
								</Grid>
							</Hidden>
						</Grid>);
				}}
			</Consumer>
		);
	}
}

const divFormStyle = {
	paddingLeft: 40,
	paddingRight: 40
};
