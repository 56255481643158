import React, {Component} from "react";
import { Grid, Paper, FormControl, InputLabel, Select, Input, MenuItem, Button, Typography, FormHelperText,
	Hidden } from "@material-ui/core";
import SelectionOverview from "./bits/SelectionOverview";
import moment from 'moment';
import 'moment/min/locales'
import {Consumer} from "../context";
import BackButton from "./bits/BackButton";
import {KeyboardArrowRight} from "@material-ui/icons";
moment.locale('hr');

export default class FormVehicle extends Component {
	componentDidMount() {
		// Pull dispatch from context
		const {dispatch} = this.props.context;
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_APP_BAR_VISIBILITY', payload: true});
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_SERVICE_NAVIGATION_VISIBILITY', payload: true});
	}

	// Handles on continue button click
	onContinueButtonClick = (dispatch) => {
		dispatch({type: 'NEXT_STEP'})
	};

	// Handles on change event
	onChangeEvent = (dispatch, e) =>  {
		// Set target name and values
		const { target: { value, name } } = e;
		// Dispatch daa
		dispatch({type: 'HANDLE_SIMPLE_FORM_DATA_CHANGE', payload: {'fieldName': name, 'value': value}});
	};

	// Handles on back button click
	onBackButtonClick = (dispatch) => {
		dispatch({type: 'PREVIOUS_STEP'})
	};

	render() {
		return (
			<Consumer>
				{value => {
					const { formData } = value;
					// Check if button should be enabled
					const isButtonEnabled = formData.vehicleModel.length && formData.vehicleRegistrationNumber
						&& formData.vehicleIdentificationNumber.length;

					return (
						<Grid container>
							<Grid item xs={12} md={7}>
								<Paper className={"paper"} elevation={2}>
									<Typography variant="h5" style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
										Odaberi Vozilo
									</Typography>
									<div style={divFormStyle}>
										<form autoComplete="off" style={{marginBottom: 20, fontSize: 15}}>
											<FormControl fullWidth={true} margin={"normal"}>
												<InputLabel htmlFor="txtVehicleModel">Model *</InputLabel>
												<Input
													id="txtVehicleModel"
													name={"vehicleModel"}
													onChange={this.onChangeEvent.bind(this, value.dispatch)}
													value={formData.vehicleModel}
													inputProps={{maxLength: 300}}
													required />
												<FormHelperText style={{color: 'red'}}>Obvezno polje</FormHelperText>
											</FormControl>
											<FormControl fullWidth={true} margin={"normal"}>
												<InputLabel htmlFor="txtVehicleRegistrationNumber">Reg. Oznake *</InputLabel>
												<Input
													id="txtVehicleRegistrationNumber"
													name={"vehicleRegistrationNumber"}
													onChange={this.onChangeEvent.bind(this, value.dispatch)}
													value={formData.vehicleRegistrationNumber}
													inputProps={{maxLength: 300}}
													required />
												<FormHelperText style={{color: 'red'}}>Obvezno polje</FormHelperText>
											</FormControl>
											<FormControl fullWidth={true} margin={"normal"}>
												<Hidden smUp>
													<InputLabel htmlFor="txtVin" shrink={true}>Ident Br. Vozila (broj šasije/VIN) *</InputLabel>
												</Hidden>
												<Hidden xsDown>
													<InputLabel htmlFor="txtVin">Ident Br. Vozila (broj šasije/VIN) *</InputLabel>
												</Hidden>
												<Input
													id="txtVin"
													name={"vehicleIdentificationNumber"}
													onChange={this.onChangeEvent.bind(this, value.dispatch)}
													value={formData.vehicleIdentificationNumber}
													inputProps={{maxLength: 300}}
													required />
												<FormHelperText style={{color: 'red'}}>Obvezno polje</FormHelperText>
											</FormControl>
											<Grid container spacing={8} style={{marginBottom: 10, marginTop: 10}}>
												<Grid item xs={6}>
													<FormControl fullWidth={true}>
														<InputLabel>Mjesec</InputLabel>
														<Select
															value={formData.vehicleRegistrationMonth}
															name={"vehicleRegistrationMonth"}
															onChange={this.onChangeEvent.bind(this, value.dispatch)}
															input={<Input id="month" />}
														>
															<MenuItem key={-1} value={''}>{"--Mjesec--"}</MenuItem>
															{Array.apply(0, Array(12)).map(function (_, i) {
																return <MenuItem key={i} value={i+1}>
																	{moment().month(i).format('MMMM')}
																</MenuItem>
															})}
														</Select>
													</FormControl>
												</Grid>
												<Grid item xs={6}>
													<FormControl fullWidth={true}>
														<InputLabel>Godina</InputLabel>
														<Select
															value={formData.vehicleRegistrationYear}
															name={"vehicleRegistrationYear"}
															onChange={this.onChangeEvent.bind(this, value.dispatch)}
															input={<Input id="year" />}
														>
															<MenuItem key={-1} value={''}>{"--Godina--"}</MenuItem>
															{(getArrayOfYears()).map(function (i,j) {
																return <MenuItem key={j} value={i}>
																	{i}
																</MenuItem>
															})}
														</Select>
													</FormControl>
												</Grid>
											</Grid>
											<FormControl fullWidth={true}>
												<Hidden smUp>
													<InputLabel htmlFor="txtVin" shrink={true}>Stanje brojača kilometara *</InputLabel>
												</Hidden>
												<Hidden xsDown>
													<InputLabel htmlFor="txtVin">Stanje brojača kilometara *</InputLabel>
												</Hidden>
												<Input
													id="txtVin"
													name={"vehicleKmCounter"}
													onChange={this.onChangeEvent.bind(this, value.dispatch)}
													value={formData.vehicleKmCounter}
													inputProps={{maxLength: 9}}
												/>
											</FormControl>
										</form>
									</div>
								</Paper>
								<Grid item xs={12} style={{paddingRight: 10}}>
									<Button style={{ float: 'right' }}
													variant="contained"
													color="primary"
													disabled={!isButtonEnabled}
													onClick={this.onContinueButtonClick.bind(this, value.dispatch)}
										>Dalje na pregled
										<KeyboardArrowRight style={{marginLeft: 5}} />
									</Button>
								</Grid>
								<Grid item xs={12}>
									<div style={{marginLeft: 10}}>
										<BackButton onClick={this.onBackButtonClick.bind(this, value.dispatch)} />
									</div>
								</Grid>
							</Grid>
							<Hidden smDown>
								<Grid item md={5}>
									<SelectionOverview
										values={value}
									/>
								</Grid>
							</Hidden>
						</Grid>
					)
				}}
			</Consumer>
		);
	}
}

const divFormStyle = {
	paddingLeft: 40,
	paddingRight: 40
};

// Get array of years
const getArrayOfYears = () => {
	let arrYears = [];
	// Get current year
	let currentYear = new Date().getFullYear();
	// Get minimum year
	let minYear = currentYear - 100;

	for (let year = currentYear ; year >= minYear; year--) {
		arrYears.push(year);
	}

	return arrYears;
};