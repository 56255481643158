import React, {Component} from 'react';
import isEmail from "validator/lib/isEmail";
import axios from "axios";
import config from "./config";

const Context = React.createContext('test');

const reducer = (state, action) => {
	// Validates field by field name and value
	const validateField = (fieldName, value) => {
		let formValidation = state.formValidation;
		// Switch field name
		switch(fieldName) {
			case 'clientEmail':
				formValidation.clientEmailValid = isEmail(value);
				break;
			default:
				break;
		}
		return formValidation;
	};

	switch (action.type) {
		case 'NEXT_STEP':
			return {
				...state,
				step: state.step + 1
			};
		case 'PREVIOUS_STEP':
			let step = 0;
			// Check if state is larger than zero
			if(state.step > 0) {
				step = state.step - 1
			}
			return {
				...state,
				step: step
			};
		case 'HANDLE_BACK_TO_START':
			return {
				...state,
				step: 1
			};
		case 'DETERMINE_APP_BAR_VISIBILITY':
			return {
				...state,
				appBarVisible: action.payload
			};
		case 'DETERMINE_SERVICE_NAVIGATION_VISIBILITY':
			return {
				...state,
				serviceNavigationVisible: action.payload
			};
		case 'HANDLE_SIMPLE_CHANGE':
			return {
				...state,
				[action.payload['fieldName']]: action.payload['value']
			};
		case 'HANDLE_SIMPLE_FORM_DATA_CHANGE':
			// Get service operation from state
			let sfStateField = state.formData;
			// Set field
			sfStateField[action.payload['fieldName']] = action.payload['value'];
			// Validate field and get form validation array back
			const formValidation = validateField(action.payload['fieldName'], action.payload['value']);
			// Set values to state
			return {
				...state,
				[sfStateField]: sfStateField,
				[formValidation]: formValidation
			};
		case 'HANDLE_SIMPLE_CHECKBOX_CHANGE':
			// Get state field
			let scbStateField = state.formData;
			// Get field name
			let scbFieldName = action.payload['fieldName'];
			// Set field
			scbStateField[scbFieldName] =  action.payload['checked'] ? 1 : 0;
			// Set values to state
			return {
				...state,
				[scbStateField]: scbStateField
			};
		case 'HANDLE_CHECKBOX_CHANGE':
			// Get service operation from state
			let cbStateField = state.formData;
			// Set field name
			let fieldName = action.payload['fieldName'];
			// Get ID value as number
			const value = Number(action.payload['value']);
			// Set field name
			const isChecked = action.payload['isChecked'];
			// Check if value is checked and not already in list of selected items
			if(isChecked && !cbStateField[fieldName].includes(value)) {
				cbStateField[fieldName].push(value);
			} else if (!isChecked && cbStateField[fieldName].includes(value)){
				cbStateField[fieldName] = cbStateField[fieldName].filter(item => item !== value);
			}
			// Set values to state
			return {
				...state,
				[cbStateField]: cbStateField
			};
		case 'HANDLE_FILE_UPLOAD':
			// Get service operation from state
			let fuStateField = state.formData;
			// Check if maximum number of files has been exceeded
			if (fuStateField.arrFile.length < state.maxFilesAllowed) {
				action.payload['files'].map(file => (
					fuStateField.arrFile.push({
						...file,
						preview: URL.createObjectURL(file)
					}))
				);
			}
			// Set values to state
			return {
				...state,
				[fuStateField]: fuStateField
			};
		case 'HANDLE_DATE_CHANGE':
			// Get service operation from state
			let dcStateField = state.formData;
			// Set field name
			let dcFieldName = action.payload['fieldName'];
			// Set field
			dcStateField[dcFieldName] = action.payload['date'];
			// Set values to state
			return {
				...state,
				[dcStateField]: dcStateField
			};
		case 'HANDLE_APPOINTMENT_CHANGE_AND_CONTINUE':
			// Get form data
			let acStateField = state.formData;
			// Set field
			acStateField['appointmentTermId'] =  action.payload['value'];
			// Set values to state
			return {
				...state,
				[dcStateField]: dcStateField,
				step: state.step + 1
			};
		default:
			return state;
	}
};

export class Provider extends Component {
	// Populates state's vehicle brands data
	loadVehicleBrandsData = () => {
		// Pull dispatch from state
		const {dispatch} = this.state;
		// Set app-bar visibility to false
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'vehicleBrandsLoading', 'value': true}});
		// Fire get call and return promise
		axios.get("/api/brands")
			.then((res) => {
				if (res.data && res.data.success) {
					let arrVehicleBrands = [];
					// Iterate array and set fields
					res.data.data.forEach(item => {
						arrVehicleBrands.push({id: item.id, name: item.name});
					});
					// Set app-bar visibility to false
					dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'arrVehicleBrands', 'value': arrVehicleBrands}});
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				// Set loading to false
				dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'vehicleBrandsLoading', 'value': false}});
			});
	};

	// Loads service operations data from API
	loadServiceOperationsData = () => {
		// Pull dispatch from state
		const {dispatch} = this.state;
		// Set app-bar visibility to false
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'serviceOpLoading', 'value': true}});
		// Fire get call and return promise
		axios.get("/api/services")
			.then((res) => {
				if (res.data && res.data.success) {
					let arrServiceOperation = [];
					// Iterate array and set fields
					res.data.data.forEach(serviceOperation => {
						arrServiceOperation.push({
							id: serviceOperation.id, name: serviceOperation.name,
							description: serviceOperation.description,
							iconPath: serviceOperation.iconPath,
							categoryType: serviceOperation.categoryType
						});
					});
					// Set app-bar visibility to false
					dispatch({
						type: 'HANDLE_SIMPLE_CHANGE',
						payload: {'fieldName': 'arrServiceOperation', 'value': arrServiceOperation}
					});
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				// Set loading to false
				dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'serviceOpLoading', 'value': false}});
			});
	};

	// Loads workshop data from API
	loadWorkshopsData = () => {
		// Pull dispatch from state
		const {dispatch} = this.state;
		// Set app-bar visibility to false
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'workshopsLoading', 'value': true}});
		// Fire get call and return promise
		axios.get("/api/workshops")
			.then((res) => {
				if (res.data && res.data.success) {
					let arrWorkshop = [];
					// Iterate array and set fields
					res.data.data.forEach(workshop => {
						arrWorkshop.push({
							id: workshop.id,
							locationName: workshop.locationName,
							locationAddress: workshop.locationAddress,
							employeeName: workshop.employeeName
						});
					});
					// Set app-bar visibility to false
					dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'workshop', 'value': arrWorkshop}});
					// Check if array contains any items
					if(arrWorkshop.length) {
						// Set first ID value as default value
						dispatch({type: 'HANDLE_SIMPLE_FORM_DATA_CHANGE', payload: {'fieldName': 'workshopId', 'value': arrWorkshop[0].id}});
					}
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				// Set loading to false
				dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'workshopsLoading', 'value': false}});
			});
	};

	// Loads mobility data from API
	loadMobilityData = () => {
		// Pull dispatch from state
		const {dispatch} = this.state;
		// Display loading icon
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'mobilityLoading', 'value': true}});
		// Fire get call and return promise
		axios.get("/api/mobility")
			.then((res) => {
				if (res.data && res.data.success) {
					let arrMobility = [];
					// Iterate array and set fields
					res.data.data.forEach(mobility => {
						arrMobility.push({
							id: mobility.id,
							name: mobility.name
						});
					});
					// Set app-bar visibility to false
					dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'arrMobility', 'value': arrMobility}});
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				// Set loading to false
				dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'mobilityLoading', 'value': false}});
			});
	};

	// Loads languages data from API
	loadLanguages = () => {
		// Pull dispatch from state
		const {dispatch} = this.state;
		// Display loading icon
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'languagesLoading', 'value': true}});
		// Fire get call and return promise
		axios.get("/api/languages")
			.then((res) => {
				if (res.data && res.data.success) {
					let arrLang = [];
					// Iterate array and set fields
					res.data.data.forEach(language => {
						arrLang.push({
							id: language.id,
							name: language.name
						});
					});
					// Set app-bar visibility to false
					dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'languages', 'value': arrLang}});
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				// Set loading to false
				dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'languagesLoading', 'value': false}});
			});
	};

	// Loads appointment terms data from API
	loadAppointmentTermsData = () => {
		// Pull dispatch from state
		const {dispatch} = this.state;
		// Pull formData object from context
		const {formData} = this.state;
		// Display loading icon
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'appointmentsLoading', 'value': true}});
		// Fire get call and return promise
		axios.post("/api/findAppointments", {
			workshopId: formData.workshopId,
			appointmentDateTypeDate: formData.appointmentDateTypeDate
		})
			.then((res) => {
				if (res.data && res.data.success) {
					let arrAppointmentTerm = [];
					// Iterate array and set fields
					res.data.data.forEach(appTerm => {
						arrAppointmentTerm.push({
							id: appTerm.id,
							appointmentTypeText: appTerm.appointmentTypeText.toLowerCase(),
							appointmentDateTime: appTerm.appointmentDateTime,
							appointmentWorkshopName: appTerm.appointmentWorkshopName,
							serviceDurationDays: appTerm.serviceDurationDays
						});
					});
					// Set app-bar visibility to false
					dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'arrAppointmentTerm', 'value': arrAppointmentTerm}});
				}
			})
			.catch((err) => {
				console.error(err);
			})
			.then(() => {
				// Set loading to false
				dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'appointmentsLoading', 'value': false}});
			});
	};

	// Posts appointment data to api
	postAppointmentData = () => {
		// Pull dispatch from state
		const {dispatch} = this.state;
		// Pull formData object from context
		const {formData} = this.state;
		// POST call to API
		axios.post("/api/insertAppointment", {
			vehicleBrandId: formData.vehicleBrandId,
			serviceOperationIds: formData.arrServiceOperationId,
			workshopId: formData.workshopId,
			appointmentDateTypeId: formData.appointmentDateTypeId,
			appointmentDateTypeDate: formData.appointmentDateTypeDate,
			appointmentTimeTypeId: formData.appointmentTimeTypeId,
			mobilityId: formData.mobilityId,
			note: formData.note,
			files: formData.arrFile,
			appointmentId: formData.appointmentId,
			clientEmail: formData.clientEmail,
			clientFirstName: formData.clientFirstName,
			clientLastName: formData.clientLastName,
			clientStreetAndNumber: formData.clientStreetAndNumber,
			clientPostalCode: formData.clientPostalCode,
			clientCity: formData.clientCity,
			clientCountry: formData.clientCountry,
			clientMobile: formData.clientMobile,
			clientTelephone: formData.clientTelephone,
			clientLanguageId: formData.clientLanguageId,
			vehicleModel: formData.vehicleModel,
			vehicleRegistrationNumber: formData.vehicleRegistrationNumber,
			vehicleIdentificationNumber: formData.vehicleIdentificationNumber,
			vehicleRegistrationMonth: formData.vehicleRegistrationMonth,
			vehicleRegistrationYear: formData.vehicleRegistrationYear,
			vehicleKmCounter: formData.vehicleKmCounter,
			overviewCreateUserAccount: formData.overviewCreateUserAccount,
			overviewAcceptTerms: formData.overviewAcceptTerms,
			furtherOptionsIds: formData.arrFurtherOptionsId
		})
			.then(function (response) {
				console.log(response);
				dispatch({type: 'NEXT_STEP'});
			})
			.catch(function (error) {
				console.log(error);
				// Open notification
				dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'errorNotificationOpen', 'value': true}});
			});
	};

	state = {
		step: 1,
		appBarVisible: true,
		serviceNavigationVisible: true,
		tabs: [{
			tabNumber: 1,
			tabText: "Marka"
		}, {
			tabNumber: 2,
			tabText: "Usluga"
		}, /*{
			tabNumber: 2,
			tabText: "Termin"
		}*/ {
			tabNumber: 3,
			tabText: "Klijent"
		}, {
			tabNumber: 4,
			tabText: "Vozilo"
		}, {
			tabNumber: 5,
			tabText: "Pregled"
		}, {
			tabNumber: 6,
			tabText: "Potvrda"
		}],
		serviceTypes: [{
			id: 1,
			name: "Održavanje i potrošni dijelovi"
		}, {
			id: 2,
			name: "Zamjena ulja"
		}, {
			id: 3,
			name: "Kočnice"
		}, {
			id: 4,
			name: "Provjere"
		}, {
			id: 5,
			name: "Stakla"
		}, {
			id: 6,
			name: "Klima uređaj"
		}, {
			id: 7,
			name: "Gume i kotači"
		}],
		languages: [],
		arrServiceOperation: [],
		workshop: [],
		appointmentTimeType: [{
			id: 1,
			name: "U bilo koje vrijeme"
		},{
			id: 2,
			name: "Prijepodne"
		},{
			id: 3,
			name: "Poslijepodne"
		}],
		arrFurtherOptions: [{
			id: 1,
			name: "Želim čekati da se popravak na mom vozilu dovrši",
		}, {
			id: 2,
			name: "Potrebna mi je mobilnost",
		}, {
			id: 3,
			name: "Želim poziv auto-kuće",
		}],
		arrMobility: [],
		arrVehicleBrands: [],
		arrAppointmentTerm: [],
		vehicleBrandsLoading: false,
		serviceOpLoading: false,
		workshopsLoading: false,
		mobilityLoading: false,
		appointmentsLoading: false,
		languagesLoading: false,
		formServicesMounted: false,
		formClientMounted: false,
		welcomeLoginFormMounted: false,
		maxFilesAllowed: 2,
		errorNotificationOpen: false,
		formData: {
			vehicleBrandId: "",
			arrServiceOperationId: [],
			selectedServiceTypeId: 1,
			workshopId: null,
			appointmentDateTypeId: 1,
			appointmentDateTypeDate: new Date(),
			appointmentTimeTypeId: 1,
			arrFurtherOptionsId: [],
			mobilityId: '',
			note: '',
			arrFile: [],
			appointmentTermId: 1,
			clientEmail: '',
			clientFirstName: '',
			clientLastName: '',
			clientStreetAndNumber: '',
			clientPostalCode: '',
			clientCity: '',
			clientCountry: '',
			clientMobile: '',
			clientTelephone: '',
			clientLanguage: '',
			clientLanguageId: 1,
			vehicleModel: '',
			vehicleRegistrationNumber: '',
			vehicleIdentificationNumber: '',
			vehicleRegistrationMonth: '',
			vehicleRegistrationYear: '',
			vehicleKmCounter: '',
			overviewCreateUserAccount: 0,
			overviewAcceptTerms: 0
		},
		formValidation: {
			clientEmailValid: true
		},
		dispatch: action => this.setState(state => reducer(state, action)),
		loadVehicleBrandsData: this.loadVehicleBrandsData,
		loadServiceOperationsData: this.loadServiceOperationsData,
		loadWorkshopsData: this.loadWorkshopsData,
		loadMobilityData: this.loadMobilityData,
		loadLanguages: this.loadLanguages,
		loadAppointmentTermsData: this.loadAppointmentTermsData,
		postAppointmentData: this.postAppointmentData
	};

	render() {
		// Add config data to state which will be passed down the components
		const state = {
			config: {
				...config
			},
			...this.state
		};

		return (
			<Context.Provider value={state}>
				{this.props.children}
			</Context.Provider>
		)
	}
}

export const Consumer = Context.Consumer;

