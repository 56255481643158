import React, {Component} from 'react';
import {
	Grid, ListItem, ListItemText, Paper, Typography, Button, List} from "@material-ui/core";
import {Build, AssignmentTurnedIn} from "@material-ui/icons";
import moment from 'moment';
import 'moment/min/locales'
import {Consumer} from "../context";
import NameValueGrid from "./NameValueGrid";
moment.locale('hr');

class FormConfirmation extends Component {
	componentDidMount() {
		// Pull dispatch from context
		const {dispatch} = this.props.context;
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_APP_BAR_VISIBILITY', payload: true});
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_SERVICE_NAVIGATION_VISIBILITY', payload: true});
	}

	// Handles back to start click event
	onBackToStartClick = () => {
		// Reload application
		window.location.reload();
	};

	render() {
		return (
			<Consumer>
				{value => {
					// Pull from state
					const { formData } = value;
					//let appointmentTerm = '';
					let workshop = '';
					let arrServiceOperation = [];
					//let appointmentDateString = '';

					/*const capitalizeFirstLetter = (word) => {
						return word.charAt(0).toUpperCase() + word.slice(1);
					};*/

					// Check if appointment term ID value and array of appointment terms are defined
					/*if(formData.appointmentTermId !== '' && value.arrAppointmentTerm.length) {
						value.arrAppointmentTerm.forEach(appTerm => {
							if (appTerm.id === formData.appointmentTermId) {
								appointmentTerm = appTerm;
							}
						});

						// Get appointment datetime
						appointmentDateString =
							capitalizeFirstLetter(moment(appointmentTerm.appointmentDateTime).format('dddd, DD MMM YYYY, hh:mm'));
					}*/

					// Check if workshop ID value and array of workshops are defined
					if((formData.workshopId !== '') && value.workshop.length) {
						value.workshop.forEach(ws => {
							if (ws.id === formData.workshopId) {
								workshop = ws;
							}
						})
					}

					// Check if service operation ID value and array of workshops are defined
					if((formData.arrServiceOperationId.length) && value.arrServiceOperation.length) {
						value.arrServiceOperation.forEach(so => {
							if (formData.arrServiceOperationId.includes(so.id)) {
								arrServiceOperation.push(so);
							}
						})
					}

					return (
						<Grid container>
							<Grid item xs={12}>
								<Paper className={"paper"} elevation={2}>
									<Typography variant="h5" style={{fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10}}>
										<AssignmentTurnedIn color={"primary"} style={{marginRight: 10}} /> Potvrda
									</Typography>
									<Grid container>
										<Grid item lg={6} xs={12}>
											<Paper className={"paper"}>
												<Typography variant="h6" style={{fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10}}>
													Podaci:
												</Typography>

												<NameValueGrid name={"Ime i Prezime"}
																			 value={formData.clientFirstName + ' ' + formData.clientLastName}/>
												<NameValueGrid name={"Email"}
																			 value={formData.clientEmail}/>
												<NameValueGrid name={"Lokacija"}
																			 value={workshop.locationName + ', ' + workshop.locationAddress} />

												{/*<Table id={"tblConfirmation"}>
													<TableBody>
														<TableRow key={1}>
															<TableCell component="th" scope="row">
																Ime i Prezime
															</TableCell>
															<TableCell>
																{formData.clientFirstName} {formData.clientLastName}
															</TableCell>
														</TableRow>
														<TableRow key={2}>
															<TableCell component="th" scope="row">
																Email
															</TableCell>
															<TableCell>
																{formData.clientEmail}
															</TableCell>
														</TableRow>
														<TableRow key={3}>
															<TableCell component="th" scope="row">
																Termin
															</TableCell>
															<TableCell>
																{appointmentDateString}
															</TableCell>
														</TableRow>
														<TableRow key={4}>
															<TableCell component="th" scope="row">
																Procijenjeno trajanje popravka
															</TableCell>
															<TableCell>
																{appointmentTerm.serviceDurationDays} Dan(a)
															</TableCell>
														</TableRow>
														<TableRow key={5}>
															<TableCell component="th" scope="row">
																Lokacija
															</TableCell>
															<TableCell>
																<ListItem button>
																	<Place />
																	<ListItemText
																		key={workshop.id}
																		primary={workshop.locationName}
																		secondary={workshop.locationAddress}
																	/>
																</ListItem>
															</TableCell>
														</TableRow>
														<TableRow key={6}>
															<TableCell component="th" scope="row">
																Servisni radnik/radnica
															</TableCell>
															<TableCell>
																{workshop.employeeName}
															</TableCell>
														</TableRow>
													</TableBody>
												</Table>*/}
											</Paper>
										</Grid>
										<Grid item lg={6} xs={12}>
											<Paper className={"paper"}>
												<Typography variant="h6" style={{fontWeight: 'bold', textTransform: 'uppercase', marginBottom: 10}}>
													Usluge koje treba provesti:
												</Typography>
												<List>
													{arrServiceOperation.map((serviceOperation) => {
															return <ListItem key={serviceOperation.id}>
																<Typography variant="body2">
																	<Build />
																</Typography>
																<ListItemText primary={serviceOperation.name} secondary={serviceOperation.description} />
															</ListItem>
														}
													)}
												</List>
											</Paper>
										</Grid>
									</Grid>
								</Paper>
								<Grid item xs={12} style={{paddingRight: 30}}>
									<Button style={{float: 'right'}}
													variant="contained"
													color="primary"
													onClick={this.onBackToStartClick}
									>Natrag na početnu stranicu</Button>
								</Grid>
							</Grid>
						</Grid>
					)
				}}
			</Consumer>
		);
	}
}

export default FormConfirmation;