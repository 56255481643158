import React from 'react';
import {Typography} from "@material-ui/core";

export default () => {
	return (
		<div>
			<Typography variant="h2">
				<span style={{color: 'red'}}>404</span> Page Not Found
			</Typography>
		</div>
	);
};
