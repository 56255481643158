import React, {Component} from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";

class NameValueGrid extends Component {
	render() {
		return (
			<Grid container style={{margin: 10, padding: 10}}>
				{/* Name */}
				<Grid item lg={6} xs={12}>
					<Paper elevation={0}>
						<Typography variant="body1">
							{this.props.name}
						</Typography>
					</Paper>
				</Grid>
				{/* Value */}
				<Grid item lg={6} xs={12}>
					<Paper elevation={0}>
						<Typography variant="body1" style={{fontWeight: 'bold'}}>
							{this.props.value}
						</Typography>
					</Paper>
				</Grid>
			</Grid>
		)
	}
}

export default NameValueGrid;