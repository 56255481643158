import {Grid, ListItem, ListItemText, Paper, Typography} from "@material-ui/core";
import React, {Component} from "react";
import {Person, StayPrimaryPortrait, Call, DirectionsCar, CloudUpload, Build, Place} from "@material-ui/icons";
import moment from 'moment';
import 'moment/min/locales'
moment.locale('hr');

export default class SelectionOverview extends Component {
	render() {
		const { values } = this.props;
		// Initialize vehicle brand name
		let vehicleBrandName = '';
		let clientLanguageName = '';
		let vehRegPeriodVisible = false;

		// Iterate vehicle brands array
		values.arrVehicleBrands.forEach((vehicleBrand) => {
			if(vehicleBrand.id === values.formData.vehicleBrandId) {
				vehicleBrandName = vehicleBrand.name
			}
		});

		// Iterate vehicle brands array
		values.languages.forEach((language) => {
			if(language.id === values.formData.clientLanguageId) {
				clientLanguageName = language.name
			}
		});

		// Check if registration segment should be visible
		if((values.formData.vehicleRegistrationMonth !== '') || values.formData.vehicleRegistrationYear) {
			vehRegPeriodVisible = true;
		}

		return (
			<React.Fragment>
				{/* Client grid*/}
				<Grid item style={{display: values.formData.clientEmail !== '' ? 'block' : 'none', marginBottom: 10}}>
					<Paper style={paperStyle}>
						<div>
							<Typography variant="body1">
								<Person color={"primary"}/> <span>{values.formData.clientFirstName} {values.formData.clientLastName}</span>
							</Typography>
							<Typography variant="body1">
								{values.formData.clientEmail}
							</Typography>
							<Typography variant="body2">
								{values.formData.clientStreetAndNumber} {values.formData.clientPostalCode}
							</Typography>
							<Typography variant="body2">
								{values.formData.clientCity} {values.formData.clientCountry}
							</Typography>
							<Typography variant="body2" style={{display: values.formData.clientMobile !== '' ? 'block' : 'none'}}>
								<StayPrimaryPortrait  /> {values.formData.clientMobile}
							</Typography>
							<Typography variant="body2" style={{display: values.formData.clientTelephone !== '' ? 'block' : 'none'}}>
								<Call  /> {values.formData.clientTelephone}
							</Typography>
							<Typography variant="body2">
								{clientLanguageName}
							</Typography>
						</div>
					</Paper>
				</Grid>
				{/* Vehicle grid*/}
				<Grid item style={{marginBottom: 10}}>
					<Paper style={paperStyle}>
						<div>
							<Typography variant="body1">
								<DirectionsCar color={"primary"} /> <span>{values.formData.vehicleRegistrationNumber}</span>
							</Typography>
							<Typography variant="body1">
								{values.formData.vehicleIdentificationNumber}
							</Typography>
							<Typography variant="body2">
								<span>{vehicleBrandName}</span>&nbsp;
								<span>{values.formData.vehicleModel}</span>
							</Typography>
							<div style={{display: vehRegPeriodVisible ? 'block' : 'none'}}>
								<Typography variant="body2">
									<span>Prva registracija</span>
									<span style={{marginLeft: 15}}>{moment(values.formData.vehicleRegistrationMonth, 'M').format('MMMM')} {values.formData.vehicleRegistrationYear}.</span>
								</Typography>
							</div>
							<div style={{display: values.formData.vehicleKmCounter !== '' ? 'block' : 'none'}}>
								<Typography variant="body2">
									<span>Trenutačno stanje brojača kilometara:</span>
									<span style={{marginLeft: 15}}>{values.formData.vehicleKmCounter} km</span>
								</Typography>
							</div>
						</div>
					</Paper>
				</Grid>
				{/* Service operation grid*/}
				<Grid item style={{display: values.formData.arrServiceOperationId.length !== 0 ? 'block' : 'none', marginBottom: 10}}>
					<Paper style={paperStyle}>
						<Build color={"primary"} />
						<div style={{marginLeft: 20}}>
							{values.arrServiceOperation.map((serviceOp) => {
									if (values.formData.arrServiceOperationId.includes(serviceOp.id)) {
										return <React.Fragment key={serviceOp.id}>
											<Typography variant="body2">
												- {serviceOp.name}
												</Typography>
										</React.Fragment>
									}
									return '';
								}
							)}
						</div>
					</Paper>
				</Grid>
				{/* Workshop name/location grid*/}
				<Grid item style={{marginBottom: 10}}>
					<Paper style={paperStyle}>
						<div>
							<ListItem button>
								<Place color={"primary"} />
								{Object.keys(values.workshop).map((key) => {
									if (values.workshop[key].id === values.formData.workshopId) {
										return <ListItemText
												key={key}
												primary={values.workshop[key].locationName}
												secondary={values.workshop[key].locationAddress}
										/>
									}
									return '';
								})
								}
							</ListItem>
						</div>
						<hr />
						<Typography variant="body2">
							<span>Datum preuzimanja</span>
						</Typography>
						<ul>
							<li>
								<Typography variant="body2">{values.formData.appointmentDateTypeId === 1 ? "Što prije moguće" : moment(values.formData.appointmentDateTypeDate).format('DD. MMMM gggg.')}</Typography>
							</li>
						</ul>
						<Typography variant="body2">
							<span>Vrijeme preuzimanja</span>
						</Typography>
						<ul>
							<li>
								<Typography variant="body2">
									{values.appointmentTimeType.map((appointmentTimeType) => {
											if (values.formData.appointmentTimeTypeId === appointmentTimeType.id) {
												return <React.Fragment key={appointmentTimeType.id}>
													<span>{appointmentTimeType.name}</span>
												</React.Fragment>
											}
											return '';
										}
									)}
								</Typography>
							</li>
						</ul>
						{/* Further options */}
						<div style={{display: values.formData.arrFurtherOptionsId.length !== 0 ? 'block' : 'none', marginBottom: 10}}>
							<hr />
							<Typography variant="body2">Daljnje opcije</Typography>
							<ul>
								{Object.keys(values.arrFurtherOptions).map((foKey) => {
										if (values.formData.arrFurtherOptionsId.includes(values.arrFurtherOptions[foKey].id)) {
											return <React.Fragment key={foKey}>
												<li>
													<Typography variant="body2">{values.arrFurtherOptions[foKey].name}</Typography>
													<ul>
														{values.arrMobility.map((mobility) => {
																if (values.formData.mobilityId.includes(mobility.id) && (values.arrFurtherOptions[foKey].id === 2)) {
																	return <li key={mobility.id}>
																		<Typography variant="body2">{mobility.name}</Typography>
																	</li>
																}
																return '';
															}
														)}
													</ul>
												</li>
											</React.Fragment>
										}
										return '';
									}
								)}
							</ul>
						</div>
					</Paper>
				</Grid>
				{/* Notes and images */}
				<Grid item>
					<Paper style={paperStyle}>
						<div>
							<Typography variant="body2">
								<CloudUpload color={"primary"} style={{marginRight: 10}}/> <span>{values.formData.note !== "" ? "Napomena: (" + truncate(values.formData.note) + ")" : "Nema napomene"}</span>
							</Typography>
							<Typography variant="body2" style={{display: values.formData.arrFile.length !== 0 ? 'block' : 'none'}}>
								<span>{values.formData.arrFile.length}</span> slike/slika
							</Typography>
						</div>
					</Paper>
				</Grid>
			</React.Fragment>
		);
	}
}

function truncate(string){
	if (string.length > 50)
		return string.substring(0,50)+'...';
	else
		return string;
}

const paperStyle = {
	margin: 10,
	padding: 20
};