import React, { Component } from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  text-align: center;
  width: 100% !important;
  background: #bbb;
  color: white;
  height: 70px;
`;

const FooterText = styled.div`
	height: 100%;
	display:flex;
	justify-content: center;
	align-items: center;
`;

export class Footer extends Component {
	render() {
		return (
			<FooterContainer>
				<FooterText>Potrebna vam je pomoć? 036/351-341 | info@tomic.ba</FooterText>
			</FooterContainer>
		);
	}
}

export default Footer;