import React, {Component} from 'react';
import { Grid, Typography, FormControl, InputLabel, Input, Button, Select, MenuItem} from '@material-ui/core';
import ReactLoading from 'react-loading';
import { Consumer } from '../context';
import SnackbarContentWrapper from "./bits/CustomSnackbar"

class WelcomeLoginForm extends Component {
	// Handles on continue button click
	onContinueButtonClick = (dispatch) => {
		dispatch({type: 'NEXT_STEP'})
	};

	// Handles on change event
	onChangeEvent = (dispatch, e) =>  {
		// Set target name and values
		const { target: { value, name } } = e;
		// Dispatch daa
		dispatch({type: 'HANDLE_SIMPLE_FORM_DATA_CHANGE', payload: {'fieldName': name, 'value': value}});
	};

	componentDidMount() {
		// Pull dispatch from context
		const {dispatch} = this.props.context;
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_APP_BAR_VISIBILITY', payload: false});
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_SERVICE_NAVIGATION_VISIBILITY', payload: false});

		// Don't load data again if form has been loaded previously
		if(!this.props.context.welcomeLoginFormMounted) {
			// Load vehicle brands data
			this.props.context.loadVehicleBrandsData();
		}

		// Set form as mounted
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'welcomeLoginFormMounted', 'value': true}});
	};

	render() {
		return (
			<Consumer>
				{value => {
					// Set state as constant
					const values = value;
					// Set loading element
					let divBrands = <ReactLoading type={"spokes"} color={"#1c69d4"} />;
					// Set element only after data is loaded
					if(!values.vehicleBrandsLoading) {
						// Check if any item in array exists
						if(values.arrVehicleBrands.length) {
							divBrands = <FormControl fullWidth={true}>
								<InputLabel>Marka *</InputLabel>
								<Select
									style={{textAlign: "left"}}
									name={"vehicleBrandId"}
									value={values.formData.vehicleBrandId}
									onChange={this.onChangeEvent.bind(this, values.dispatch)}
									input={<Input id="vehicleBrandId" />}
								>
									<MenuItem key={0} value={""}>&nbsp;</MenuItem>
									{values.arrVehicleBrands.map((vehicleBrand) => {
										return <MenuItem key={vehicleBrand.id} value={vehicleBrand.id}>
											{vehicleBrand.name}
										</MenuItem>
									})}
								</Select>
							</FormControl>
						} else {
							divBrands = <SnackbarContentWrapper
								variant="error"
								message="Greška prilikom dohvata podataka."
								displayCloseIcon={false}
							/>
						}
					}

					return (
						<Grid container style={{paddingLeft: 20, paddingRight: 20, marginTop: 50}}>
							<Grid item xs={12}>
								<Grid
									container
									spacing={16}
									alignItems={"center"}
									direction={"column"}
									justify={"center"}
								>
									<Grid item xs={12} style={{textAlign: 'center'}}>
										<div>
											<Typography variant="h4" style={{fontWeight: 'bold'}}>
												BMW Servis
											</Typography>
										</div>
										<div style={{marginTop: 60}}>
											<Typography variant="h3" style={{fontWeight: 'bold'}}>
												Auto centar Tomić
											</Typography>
										</div>
										<div style={{marginTop: 60}}>
											<Typography variant="h5">
												Dobrodošli na stranicu za online naručivanje na servis
											</Typography>
										</div>

										<div style={{marginTop: 40, maxWidth: 400, marginLeft: 'auto', marginRight: 'auto'}}>
											{divBrands}
										</div>
										<div style={{ marginTop: 40, maxWidth: 300, marginLeft: 'auto', marginRight: 'auto' }}>
											<Button
												fullWidth={true}
												variant="contained"
												color="primary"
												onClick={this.onContinueButtonClick.bind(this, value.dispatch)}
												disabled={(value.formData.vehicleBrandId === "") || isNaN(value.formData.vehicleBrandId)}
											>
												Zakaži termin
											</Button>
										</div>
										<div style={{ marginTop: 10, maxWidth: 300, marginLeft: 'auto', marginRight: 'auto' }}>
											<Button
												fullWidth={true}
												variant="contained"
												color="primary"
												disabled={true}
												/*onClick={nextStep}*/
											>
												Prijava
											</Button>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)
				}}
			</Consumer>
		);
	}
}

export default WelcomeLoginForm;