import React, {Component} from 'react';
import { FormControl, Button, InputLabel, Select, Input, FormLabel, FormControlLabel, Grid, Paper,
	RadioGroup, Radio, Checkbox, TextField, Typography, Hidden, MenuItem} from '@material-ui/core';
import moment from 'moment';
import {InlineDatePicker, MuiPickersUtilsProvider} from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { Build, Place, CalendarToday, Assignment, KeyboardArrowRight, CheckCircle} from "@material-ui/icons";
/*import DropzoneWithPreview from './bits/DropzoneWithPreview'*/
import SelectionOverview from "./bits/SelectionOverview";
import ReactLoading from "react-loading";
import {Consumer} from "../context";
import SnackbarContentWrapper from "./bits/CustomSnackbar";
import BackButton from "./bits/BackButton";
import Chip from "@material-ui/core/Chip";

export class FormServices extends Component {
	componentDidMount() {
		// Pull dispatch from context
		const {dispatch} = this.props.context;
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_APP_BAR_VISIBILITY', payload: true});
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_SERVICE_NAVIGATION_VISIBILITY', payload: true});

		// Don't load data again if form has been loaded previously
		if(!this.props.context.formServicesMounted) {
			// Load service operations data
			this.props.context.loadServiceOperationsData();
			// Load service operations data
			this.props.context.loadWorkshopsData();
			// Load mobility data
			//this.props.context.loadMobilityData();
		}

		// Set form as mounted
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'formServicesMounted', 'value': true}});
	}

	// Handles on continue button click
	onContinueButtonClick = (dispatch) => {
		dispatch({type: 'NEXT_STEP'})
	};

	// Handles on back button click
	onBackButtonClick = (dispatch) => {
		dispatch({type: 'PREVIOUS_STEP'})
	};

	// Handles on continue button click
	onCheckboxChange = (dispatch, e) => {
		// Set target name and values
		const { target: { value, name, checked } } = e;
		// Dispatch daa
		dispatch({type: 'HANDLE_CHECKBOX_CHANGE', payload: {'fieldName': name, 'value': Number(value), 'isChecked': checked}});
	};

	// Handles on change event
	onChangeEvent = (dispatch, e) =>  {
		// Set target name and values
		const { target: { value, name } } = e;
		// Dispatch daa
		dispatch({type: 'HANDLE_SIMPLE_FORM_DATA_CHANGE', payload: {'fieldName': name, 'value': value}});
	};

	// Handles on change event for radio button
	onRadioButtonChangeEvent = (dispatch, e) =>  {
		// Set target name and values
		const { target: { value, name } } = e;
		// Dispatch daa
		dispatch({type: 'HANDLE_SIMPLE_FORM_DATA_CHANGE', payload: {'fieldName': name, 'value': Number(value)}});
	};

	// Handles on date change event
	onDateChangeEvent = (dispatch, name, date) =>  {
		// Dispatch data
		dispatch({type: 'HANDLE_DATE_CHANGE', payload: {'fieldName': name, 'date': date}});
	};

	// Handles on file upload event
	onFileUpload = (dispatch, files) =>  {
		// Dispatch daa
		dispatch({type: 'HANDLE_FILE_UPLOAD', payload: {'files': files}});
	};

	// Handles services filtering event
	onServiceTypeFilterClick = (dispatch, serviceType) =>  {
		// Dispatch daa
		dispatch({type: 'HANDLE_SIMPLE_FORM_DATA_CHANGE', payload: {'fieldName': 'selectedServiceTypeId',
				'value': serviceType.id}});
	};

	// Handles selected services display
	onSelectedOnlyServiceTypeFilterClick = (dispatch) =>  {
		// Dispatch daa
		dispatch({type: 'HANDLE_SIMPLE_FORM_DATA_CHANGE', payload: {'fieldName': 'selectedServiceTypeId', 'value': 0}});
	};

	render() {
		return (
			<Consumer>
				{value => {
					// Extract array of files from form data
					/*const { arrFile } = value.formData;*/

					// Renders upload error message if number of maximum upload files has been reached
					/*const renderUploadErrorMsg = () => {
						// Check if max number of files reached
						if(value.maxFilesAllowed === value.formData.arrFile.length) {
							return <Typography variant="body2" style={{color: 'red'}}>*Max broj slika: {value.maxFilesAllowed}</Typography>;
						}
						return null;
					};*/

					// Set loading element
					let divServiceOperation = <ReactLoading type={"spokes"} color={"#1c69d4"} />;
					// Set element only after data is loaded
					if(!value.serviceOpLoading) {


						// Initialize array that will hold filtered services based on selected filter
						const arrFilteredServiceOp = [];
						// Iterate through service operations and do filtering
						value.arrServiceOperation.forEach((serviceOp) => {
							if(value.formData.selectedServiceTypeId !== 0) {
								if(serviceOp.categoryType === value.formData.selectedServiceTypeId) {
									arrFilteredServiceOp.push(serviceOp);
								}
							} else {
								if(value.formData.arrServiceOperationId.includes(serviceOp.id)) {
									arrFilteredServiceOp.push(serviceOp);
								}
							}
						});

						// Check if any item in array exists
						if(value.arrServiceOperation.length) {
							// Check if filtered service operation array contains any items
							if(arrFilteredServiceOp.length) {
								divServiceOperation =
									<React.Fragment>
										{arrFilteredServiceOp.map((serviceOp) => {
												return (
													<React.Fragment key={serviceOp.id}>
														<FormControlLabel
															style={fclService}
															control={
																<Checkbox
																	color={"primary"}
																	name={"arrServiceOperationId"}
																	value={serviceOp.id.toString()}
																	checked={value.formData.arrServiceOperationId.includes(serviceOp.id)}
																	onChange={this.onCheckboxChange.bind(this, value.dispatch)}
																/>
															}
															label=
																{
																	<Grid container spacing={8}>
																		<Grid item>
																				<i className="material-icons" style={{verticalAlign: "text-top"}}>
																					{serviceOp.iconPath}
																				</i>
																		</Grid>
																		<Grid item>
																			<div>
																				<Typography variant="body2" style={{fontWeight: 'bold'}}>
																					{serviceOp.name}
																				</Typography>
																				<Typography variant="body2">
																					{serviceOp.description}
																				</Typography>
																			</div>
																		</Grid>
																	</Grid>
																}
														/>
														<br/>
													</React.Fragment>)
											}
										)}
									</React.Fragment>
							} else {
								divServiceOperation =
									<Typography variant="h5" style={{textAlign: "center"}}>
										Nijedna usluga nije odabrana
									</Typography>
							}
						} else {
							divServiceOperation = <SnackbarContentWrapper
								variant="error"
								message="Greška prilikom dohvata podataka."
								displayCloseIcon={false}
							/>
						}
					}

					// Set loading element
					let divWorkshops = <ReactLoading type={"spokes"} color={"#1c69d4"} />;
					// Set element only after data is loaded
					if(!value.workshopsLoading) {
						// Check if any item in array exists
						if(value.workshop.length) {
							divWorkshops =
								<FormControl style={{marginLeft: 20}}>
									<InputLabel>
										Radionica
									</InputLabel>
									<Select
										name={'workshopId'}
										value={value.formData.workshopId}
										onChange={this.onChangeEvent.bind(this, value.dispatch)}
										input={<Input name="workshopId"/>}
									>
										{value.workshop.map((ws) => {
												return <MenuItem key={ws.id} value={ws.id}>{ws.locationName}</MenuItem>
											}
										)}
									</Select>
								</FormControl>
						} else {
							divWorkshops = <SnackbarContentWrapper
								variant="error"
								message="Greška prilikom dohvata podataka."
								displayCloseIcon={false}
							/>
						}
					}

					/*// Set loading element
					let divMobility = <ReactLoading type={"spokes"} color={"#1c69d4"} />;
					// Set element only after data is loaded
					if(!value.mobilityLoading) {
						// Check if any item in array exists
						if(value.arrMobility.length) {
							divMobility =
								<FormControl>
									<InputLabel>Odaberite kategoriju</InputLabel>
									<NativeSelect
										name={'mobilityId'}
										value={value.formData.mobilityId}
										onChange={this.onChangeEvent.bind(this, value.dispatch)}
										input={<Input name="mobilityId" />}
									>
										<option
											key={0}
											value={""}>&nbsp;</option>
										}
										{value.arrMobility.map((mobility) => {
												return <option
													key={mobility.id}
													value={mobility.id}>{mobility.name}</option>
											}
										)}
									</NativeSelect>
								</FormControl>
						} else {
							divMobility = <SnackbarContentWrapper
								variant="error"
								message="Greška prilikom dohvata podataka."
								displayCloseIcon={false}
							/>
						}
					}*/

					return <Grid container>
						<Grid item xs={12} md={7}>
							{/* Services paper*/}
							<Paper style={paperStyle} elevation={2}>
								<div style={{marginBottom: 20}}>
									<Typography variant="h5" style={{fontWeight: 'bold'}}>
										<Build color={"primary"} style={{marginRight: 10}}/> USLUGE KOJE TREBA PROVESTI
									</Typography>
								</div>
								<div style={{marginBottom: 20}}>
									<Chip
										icon={<CheckCircle />}
										color={"primary"}
										variant = {value.formData.selectedServiceTypeId === 0 ? "default" : "outlined"}
										clickable={true}
										onClick={this.onSelectedOnlyServiceTypeFilterClick.bind(this, value.dispatch)}
										style={{marginTop: 2, marginRight: 5}}
										key={1}
										label={"Odabrane usluge"}
									/>
								</div>
								<div style={{marginBottom: 20}}>
									{value.serviceTypes.map((serviceType) => {
											return <Chip
												color={"primary"}
												variant = {value.formData.selectedServiceTypeId === serviceType.id ? "default" : "outlined"}
												clickable={true}
												onClick={this.onServiceTypeFilterClick.bind(this, value.dispatch, serviceType)}
												style={{marginTop: 2, marginRight: 5}}
												key={serviceType.id}
												label={serviceType.name}
											/>
										}
									)}
								</div>
								{divServiceOperation}
							</Paper>

							{/* Workshop/Terms/Further options paper*/}
							<Paper style={paperStyle} elevation={2}>
								<div style={{marginBottom: 20}}>
									<Typography variant="h5" style={{fontWeight: 'bold'}}>
										<Place color={"primary"} style={{marginRight: 10}}/> RADIONICA
									</Typography>
								</div>
								{divWorkshops}
								<div style={{marginBottom: 20, marginTop: 40}}>
									<Typography variant="h5" style={{fontWeight: 'bold'}}>
										<CalendarToday color={"primary"} style={{marginRight: 10}}/> ŽELJENI TERMIN
									</Typography>
								</div>

								<Grid container spacing={24} style={{marginLeft: 20}}>
									<Grid item xs={12} sm={6}>
										<FormControl component="fieldset">
											<FormLabel component="legend">Datum Preuzimanja</FormLabel>
											<RadioGroup
												aria-label="Datum Preuzimanja"
												name="appointmentDateTypeId"
												value={value.formData.appointmentDateTypeId.toString()}
												onChange={this.onRadioButtonChangeEvent.bind(this, value.dispatch)}
											>
												<FormControlLabel value="1" control={<Radio color={"primary"}/>} label="Što prije moguće"/>
												<FormControlLabel value="2" control={<Radio color={"primary"}/>} label="Termini od"/>
											</RadioGroup>
										</FormControl>

										<div style={{visibility: value.formData.appointmentDateTypeId === 2 ? 'visible' : 'hidden'}}>
											<MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
												<InlineDatePicker
													onlyCalendar
													format="DD. MMMM gggg."
													disablePast={true}
													name={'appointmentDateTypeDate'}
													value={value.formData.appointmentDateTypeDate}
													onChange={this.onDateChangeEvent.bind(this, value.dispatch, 'appointmentDateTypeDate')}/>
											</MuiPickersUtilsProvider>
										</div>
									</Grid>
									<Grid item xs={12} sm={6}>
										<FormControl component="fieldset">
											<FormLabel component="legend">Vrijeme Preuzimanja</FormLabel>
											<RadioGroup
												aria-label="Vrijeme Preuzimanja"
												name="appointmentTimeTypeId"
												value={value.formData.appointmentTimeTypeId.toString()}
												onChange={this.onRadioButtonChangeEvent.bind(this, value.dispatch)}
											>
												{value.appointmentTimeType.map((atType) => {
														return <FormControlLabel
															key={atType.id}
															value={atType.id.toString()}
															control={<Radio color={"primary"}/>}
															label={atType.name}/>
													}
												)}
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>

								<div style={{marginBottom: 20, marginTop: 40}}>
									<Typography variant="h5" style={{fontWeight: 'bold'}}>
										<Assignment color={"primary"} style={{marginRight: 10}}/> DALJNJE OPCIJE
									</Typography>
								</div>

								<FormControlLabel
									style={{marginLeft: 20}}
									control={
										<Checkbox
											color={"primary"}
											name={"arrFurtherOptionsId"}
											value={value.arrFurtherOptions[0].id.toString()}
											checked={value.formData.arrFurtherOptionsId.includes(value.arrFurtherOptions[0].id)}
											onChange={this.onCheckboxChange.bind(this, value.dispatch)}
										/>
									}
									label={value.arrFurtherOptions[0].name}
								/>
								<br/>
								{/*<FormControlLabel
									style={{marginLeft: 20}}
									control={
										<Checkbox
											color={"primary"}
											name={"arrFurtherOptionsId"}
											value={value.arrFurtherOptions[1].id.toString()}
											checked={value.formData.arrFurtherOptionsId.includes(value.arrFurtherOptions[1].id)}
											onChange={this.onCheckboxChange.bind(this, value.dispatch)}
										/>
									}
									label={value.arrFurtherOptions[1].name}
								/>
								<br/>*/}
								{/*<div
									style={{display: value.formData.arrFurtherOptionsId.includes(2) ? 'block' : 'none', margin: 20}}>
									{divMobility}
									<br/>
								</div>*/}
								<FormControlLabel
									style={{marginLeft: 20}}
									control={
										<Checkbox
											color={"primary"}
											name={"arrFurtherOptionsId"}
											value={value.arrFurtherOptions[2].id.toString()}
											checked={value.formData.arrFurtherOptionsId.includes(value.arrFurtherOptions[2].id)}
											onChange={this.onCheckboxChange.bind(this, value.dispatch)}
										/>
									}
									label={value.arrFurtherOptions[2].name}
								/>
							</Paper>

							{/* Notes/Images paper */}
							<Paper style={paperStyle} elevation={2}>
								<TextField
									label="Napomena"
									margin="normal"
									fullWidth={true}
									name={"note"}
									onChange={this.onChangeEvent.bind(this, value.dispatch)}
								/>
								{/*<div style={{marginTop: 20, marginBottom: 20}}>
									<Typography variant="h5" style={{fontWeight: 'bold'}}>
										<CloudUpload color={"primary"} style={{marginRight: 10}}/> SLIKE (MAKS. 6 MB/SLICI)
									</Typography>
								</div>
								<DropzoneWithPreview
									accept="image/jpeg, image/png, image/png, image/gif, image/bmp, image/webp"
									multiple={true}
									maxSize={6000000}
									arrFile={arrFile}
									handleFileUpload={this.onFileUpload.bind(this, value.dispatch)}
								/>
								{renderUploadErrorMsg()}*/}
							</Paper>
							<Grid item xs={12}>
								<Button style={{float: 'right', marginRight: 10}}
												disabled={!value.formData.arrServiceOperationId.length}
												variant="contained"
												color="primary"
												onClick={this.onContinueButtonClick.bind(this, value.dispatch)}
								>
									Nastavi
									<KeyboardArrowRight style={{marginLeft: 5}} />
								</Button>
							</Grid>
							<Grid item xs={12}>
								<div style={{marginLeft: 10}}>
									<BackButton onClick={this.onBackButtonClick.bind(this, value.dispatch)} />
								</div>
							</Grid>
						</Grid>
						<Hidden smDown>
							<Grid item md={5}>
								<SelectionOverview
									values={value}
								/>
							</Grid>
						</Hidden>
					</Grid>
				}}
			</Consumer>
		);
	}
}

const fclService = {
	marginLeft: 20
};

const paperStyle = {
	margin: 10,
	padding: 20
};

export default FormServices;