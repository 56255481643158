import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import MainForm from "./components/MainForm";
import FormNotFound from "./components/FormNotFound";
import {AppBar, Typography, Toolbar, Hidden} from "@material-ui/core";
import {Footer} from "./components/bits/Footer"
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import { createMuiTheme } from '@material-ui/core/styles';
import ServiceNavigation from "./components/ServiceNavigation";
import { Provider } from './context';
import { Consumer } from './context';

export const theme = createMuiTheme({
	typography: {
		useNextVariants: true,
		fontFamily: [
			'Arial',
			'"Helvetica Neue"',
			'Helvetica',
			'sans-serif'
		].join(','),
		fontSize: 12
	},
	palette: {
		primary: {main: '#1c69d4'},
		secondary: {main: '#ffad1f'},
	}
});

let coef = 0.1;
const modifyRem = (value, coef) => {
	return `${parseFloat(value) * (1 + coef)}rem`;
};

// Iterate through typography properties and apply responsive font size
Object.entries(theme.typography).forEach(function(variant) {
	if(variant[0] === 'h3' || variant[0] === 'h4' || variant[0] === 'h5' || variant[0] === 'h6'
		|| variant[0] === 'body1' || variant[0] === 'body2')
	{
		theme.typography[variant[0]] = {
			...theme.typography[variant[0]],
			/*fontSize: modifyRem(variant.fontSize, -coef * 5),*/
			[theme.breakpoints.down('xs')]: {
				fontSize: modifyRem(theme.typography[variant[0]].fontSize, -coef * 1.5),
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: modifyRem(theme.typography[variant[0]].fontSize, -coef),
			}
		};
	}
});

class App extends Component {
	render() {
		return (
			<Provider>
			<Consumer>
				{value => {
					// Pull step and tabs from state
					const {step, tabs} = value;
					// Set service navigation values
					const serviceNavigationValues = {step, tabs};
					// Set main form props
					const mainFormProps = {
						context: {
							...value
						}
					};

					// Renders app bar in regards to state field
					const renderAppBar = () => {
						if(value.appBarVisible) {
							return <AppBar color={"primary"}	position={"relative"}>
								<Toolbar>
									<Hidden xsDown>
										<Typography variant="h4" color="inherit" >
											BMW Online Naručivanje na Servis
										</Typography>
									</Hidden>
									<Hidden smUp>
										<Typography variant="h5" color="inherit" >
											BMW Online Naručivanje na Servis
										</Typography>
									</Hidden>
								</Toolbar>
							</AppBar>
						}
						return null;
					};
					// Renders service navigation in regards to state field
					const renderServiceNavigation = () => {
						if(value.serviceNavigationVisible) {
							return <Hidden xsDown>
								<div style={{marginBottom: 20, marginTop: 30}}>
									<ServiceNavigation
										values={serviceNavigationValues}
									/>
								</div>
							</Hidden>
						}
						return null;
					};

					return <Router>
						<div id={"contentWrapper"}>
							<MuiThemeProvider theme={theme}>
								<Switch>
									<Route exact path="/" render=
										{() =>
											<React.Fragment>
												<div id={"divAppBody"}>
													{renderAppBar()}
													{renderServiceNavigation()}
													<div id={"divContent"}>
														<MainForm
															{...mainFormProps}
														/>
													</div>
												</div>
												<Footer/>
											</React.Fragment>
										}/>
									<Route component={FormNotFound}/>
								</Switch>
							</MuiThemeProvider>
						</div>
					</Router>
				}}
			</Consumer>
			</Provider>
		);
	}
}

export default App;