import React, {Component} from 'react';
import FormServices from './FormServices';
/*import FormAppointment from './FormAppointment';*/
import FormClient from './FormClient';
import FormVehicle from './FormVehicle';
import FormOverview from './FormOverview';
import WelcomeLoginForm from "./WelcomeLoginForm";
import FormConfirmation from "./FormConfirmation";
import {Consumer} from "../context";

class MainForm extends Component {
	render() {
		return (
			<Consumer>
				{value => {
					switch(value.step) {
						case 1: {
							return (
								<WelcomeLoginForm context={value} />
							)
						}
						case 2: {
							return (
								<FormServices context={value} />
							)
						}
						/*case 3: {
							return (
								<FormAppointment context={value} />
							)
						}*/
						case 3: {
							return (
								<FormClient context={value} />
							)
						}
						case 4: {
							return (
								<FormVehicle context={value} />
							)
						}
						case 5: {
							return (
								<FormOverview context={value} />
							)
						}
						case 6: {
							return (
								<FormConfirmation context={value}
								/>
							)
						}
						default: {
							return (
								<WelcomeLoginForm context={value} />
							)
						}
					}
				}}
			</Consumer>
		)
	}
}

export default MainForm;