import React, {Component} from "react";
import { Grid, Paper, Button, ListItem, ListItemText, List, Checkbox, FormControlLabel, Typography, Hidden
} from "@material-ui/core";
import SelectionOverview from "./bits/SelectionOverview";
import {NearMe, Build, KeyboardArrowRight} from "@material-ui/icons";
import moment from "moment";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import SnackbarContentWrapper from "./bits/CustomSnackbar"
import {Consumer} from "../context";
import BackButton from "./bits/BackButton";

export default class FormOverview extends Component {
	componentDidMount() {
		// Pull dispatch from context
		const {dispatch} = this.props.context;
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_APP_BAR_VISIBILITY', payload: true});
		// Set app-bar visibility to false
		dispatch({type: 'DETERMINE_SERVICE_NAVIGATION_VISIBILITY', payload: true});
	}

	// Handles simple checkbox change event
	onSimpleCheckboxChange = (dispatch, e) => {
		// Set target name and values
		const { target: { name, checked } } = e;
		// Dispatch daa
		dispatch({type: 'HANDLE_SIMPLE_CHECKBOX_CHANGE', payload: {'fieldName': name, 'checked': checked}});
	};

	// Handles snackbar close event
	onSnackClose = (dispatch, event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		// Dispatch daa
		dispatch({type: 'HANDLE_SIMPLE_CHANGE', payload: {'fieldName': 'errorNotificationOpen', 'value': false}});
	};

	// Handles on back button click
	onBackButtonClick = (dispatch) => {
		dispatch({type: 'PREVIOUS_STEP'})
	};

	// Sends service data to API
	onConfirmationButtonClick = (e) => {
		// Load appointment terms data
		this.props.context.postAppointmentData();
	};

	render() {
		return (
			<Consumer>
				{value => {
					// Pull from states
					const { formData, errorNotificationOpen } = value;
					// Capitalizes first word letter
					const capitalizeFirstLetter = (word) => {
						return word.charAt(0).toUpperCase() + word.slice(1);
					};

					let arrServiceOperation = [];
					let appointmentTerm = '';
					let appointmentDateString = '';
					let workshop = '';

					// Check if service operation ID value and array of workshops are defined
					if((formData.arrServiceOperationId.length) && value.arrServiceOperation.length) {
						value.arrServiceOperation.forEach(so => {
							if (formData.arrServiceOperationId.includes(so.id)) {
								arrServiceOperation.push(so);
							}
						})
					}

					// Check if appointment term ID value and array of appointment terms are defined
					if(formData.appointmentTermId !== '' && value.arrAppointmentTerm.length) {
						value.arrAppointmentTerm.forEach(appTerm => {
							if (appTerm.id === formData.appointmentTermId) {
								appointmentTerm = appTerm;
							}
						});

						// Get appointment datetime
						appointmentDateString =
							capitalizeFirstLetter(moment(appointmentTerm.appointmentDateTime).format('dddd, DD MMM YYYY, hh:mm'));
					}

					// Check if workshop ID value and array of workshops are defined
					if((formData.workshopId !== '') && value.workshop.length) {
						value.workshop.forEach(ws => {
							if (ws.id === formData.workshopId) {
								workshop = ws;
							}
						})
					}

					return (
						<Grid container>
							<Grid item xs={12} md={7}>
								<Paper className={"paper"} elevation={2}>
									<Snackbar
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}
										open={errorNotificationOpen}
										autoHideDuration={6000}
										onClose={this.onSnackClose.bind(this, value.dispatch)}
									>
										<SnackbarContentWrapper
											variant="error"
											message="Greška prilikom slanja podataka. Pokušajte ponovo."
											onClose={this.onSnackClose.bind(this, value.dispatch)}
											displayCloseIcon={true}
										/>
									</Snackbar>
									<Typography variant="h5" style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
										Napravili ste sljedeći odabir
									</Typography>
									<div style={divOverviewStyle}>
										<Typography variant="body2">{appointmentDateString}</Typography>
										{/*<Typography variant="body2" style={{marginTop: 10}}>Procijenjeno trajanje popravka: {appointmentTerm.serviceDurationDays} Dan(a)</Typography>*/}
										<ListItem button style={{marginTop: 10}}>
											<NearMe />
											<ListItemText
												key={workshop.id}
												primary={workshop.locationName}
												secondary={workshop.locationAddress}
											/>
										</ListItem>
										<Typography variant="body2" style={{marginTop: 20}}>Usluge koje treba provesti:</Typography>
										<List>
											{arrServiceOperation.map((serviceOperation) => {
													return <ListItem key={serviceOperation.id}>
														<Typography variant="body2">
															<Build />
														</Typography>
														<ListItemText primary={serviceOperation.name} secondary={serviceOperation.description} />
													</ListItem>
												}
											)}
										</List>
										<hr/>
										<Typography variant="body2">Provjerite svoj odabir i kliknite na Potvrdi termin kako biste dovršili
											rezervaciju. Imajte na umu da gumb Potvrdi termin postaje aktivan tek nakon potvrde Općih uvjeta
											poslovanja</Typography>
										<div>
											{/*<FormControlLabel
												control={
													<Checkbox
														color={"primary"}
														name={"overviewCreateUserAccount"}
														value={value.formData.overviewCreateUserAccount}
														onChange={this.onSimpleCheckboxChange.bind(this, value.dispatch)}
													/>
												}
												label={"Stvori korisnički račun s već unesenim podacima *"}
											/>*/}
											<FormControlLabel
												control={
													<Checkbox
														color={"primary"}
														name={"overviewAcceptTerms"}
														value={value.formData.overviewAcceptTerms.toString()}
														onChange={this.onSimpleCheckboxChange.bind(this, value.dispatch)}
													/>
												}
												label={"Suglasan/suglasna sam da će se podaci koje sam ovdje unio/unijela obrađivati u svrhe mrežnog zakazivanja termina.\n" +
												"Svjestan/svjesna sam da u bilo kojem trenutku u budućnosti mogu povući ovaj pristanak na mom profilu.\n" +
												"Prihvaćam Opće uvjeta poslovanja. Kliknite ovdje kako biste pročitali Opće uvjete poslovanja."}
											/>
										</div>
									</div>
								</Paper>
								<Grid item xs={12} style={{paddingRight: 10}}>
									<Button style={{float: 'right'}}
													variant="contained"
													color="primary"
													disabled={!Number(value.formData.overviewAcceptTerms)}
													onClick={this.onConfirmationButtonClick.bind(this)}
										>Potvrdi termin
										<KeyboardArrowRight style={{marginLeft: 5}} />
									</Button>
								</Grid>
								<Grid item xs={12}>
									<div style={{marginLeft: 10}}>
										<BackButton onClick={this.onBackButtonClick.bind(this, value.dispatch)} />
									</div>
								</Grid>
							</Grid>
							<Hidden smDown>
								<Grid item md={5}>
									<SelectionOverview
										values={value}
									/>
								</Grid>
							</Hidden>
						</Grid>
					)
				}}
			</Consumer>
		);
	}
}

const divOverviewStyle = {
	paddingLeft: 40,
	paddingRight: 40,
	marginTop: 40
};
